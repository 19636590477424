<template>
	<div id="mainpage" class="flex flex-col min-h-screen home">
		<SiteHeader />

		<div class="banner-section">
			<div class="container flex items-center w-full h-full px-4 inner">
				<div class="w-full font-black leading-snug text-center text-white banner-text">
					<h1>Your one-stop shop for all <br>your metal cutting needs.</h1>
				</div>
			</div>
		</div>

		<div class="thankyou-section bg-siteGray">
			<div class="container px-4 py-10">
				<div class="mb-12 text-sm text-center lg:text-lg">
					<h2 class="mb-8 text-siteGray-2">Thank you for your kind interest in Bon Industrial Sales!</h2>
					<p>We will get back to you as soon as possible.</p>
				</div>
				<div class="flex justify-center">
					<a href="/" class="px-4 py-2 text-sm font-bold text-white duration-200 lg:text-base lg:px-6 lg:py-3 bg-siteRed-2 hover:bg-siteRed">Back to Home</a>
				</div>
			</div>
		</div>

		<SiteFooter />
	</div>
</template>

<script>
// @ is an alias to /src
import SiteHeader from '@/components/SiteHeader.vue'
import SiteFooter from '@/components/SiteFooter.vue'

export default {
	name: 'Home',
	components: {
		SiteHeader,
		SiteFooter,
	},
	mounted () {
		// function gtag(){dataLayer.push(arguments);}
		// Event snippet for Conversion - Thank you Page conversion page

		this.$gtag.event( 'conversion', { 'send_to': 'AW-349698048/VovhCNDRvsYCEIDw36YB' } );
		// gtag('event', 'conversion', {'send_to': 'AW-349698048/VovhCNDRvsYCEIDw36YB'});
	},
}
</script>

